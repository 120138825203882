import React from 'react'

import FormCheckbox from '@c/form-checkbox'

const Form = ({
  step2Question1,
  step2Question1Options,
  step2Question2,
  step2Question2Options,
  step2ButtonText,

  onNextButtonClick,

  form
}) => {
  const options1 = (step2Question1Options || '').split('\n')
  const options2 = (step2Question2Options || '').split('\n')

  return (
    <form onSubmit={onNextButtonClick.bind(this)}>
      {step2Question1 && (
        <>
          <p>{step2Question1}</p>
          <div className="row mt-24">
            {options1.map((option, i) => (
              <div key={i} className="col-12 col-lg-4 col-xl-3 mb-32">
                <FormCheckbox
                  withBorder={true}
                  required={false}
                  name={`step2-question1-option-${option}`}
                  ref={form.register()}
                >
                  {option}
                </FormCheckbox>
              </div>
            ))}
          </div>
        </>
      )}

      {step2Question2 && (
        <>
          <p className="mt-40">{step2Question2}</p>
          <div className="row mt-24">
            {options2.map((option, i) => (
              <div key={i} className="col-12 col-lg-4 col-xl-3 mb-32">
                <FormCheckbox
                  withBorder={true}
                  required={false}
                  name={`step2-question2-option-${option}`}
                  ref={form.register()}
                >
                  {option}
                </FormCheckbox>
              </div>
            ))}
          </div>
        </>
      )}

      <div className="row">
        <div className="col-12 ta-center">
          <button type="submit" className="btn btn--dark">
            {step2ButtonText}
          </button>
        </div>
      </div>
    </form>
  )
}

export default Form
