import React from 'react'

import FormCheckbox from '@c/form-checkbox'
import FormTextarea from '@c/form-textarea'

const Form = ({
  step2ButtonText,
  message,
  step2Question1,
  step2Question1Options,

  onNextButtonClick,

  form
}) => {
  const options1 = (step2Question1Options || '').split('\n')

  return (
    <form onSubmit={onNextButtonClick}>
      <div className="row">
        {step2Question1 && (
          <>
            <p className="col-12 mb-24">{step2Question1}</p>

            {options1.map((option, i) => (
              <div key={i} className="col-12 col-lg-4 mb-32">
                <FormCheckbox
                  withBorder={true}
                  required={false}
                  name={`step2-question1-option-${option}`}
                  ref={form.register()}
                >
                  {option}
                </FormCheckbox>
              </div>
            ))}
          </>
        )}

        <div className="col-12 mb-32">
          <FormTextarea
            name="message"
            placeholder={message}
            required={true}
            ref={form.register({
              required: 'Required'
            })}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 ta-center">
          <button type="submit" className="btn btn--dark">
            {step2ButtonText}
          </button>
        </div>
      </div>
    </form>
  )
}

export default Form
