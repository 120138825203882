import React from 'react'

import FormInput from '@c/form-input'

const Form = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  step1ButtonText,

  onNextButtonClick,

  form
}) => {
  return (
    <form onSubmit={onNextButtonClick.bind(this)}>
      <div className="row">
        <div className="col-12 col-lg-6 mb-32">
          <FormInput
            name="name"
            placeholder={firstName}
            required={true}
            ref={form.register({
              required: 'Required'
            })}
          />
        </div>
        <div className="col-12 col-lg-6 mb-32">
          <FormInput
            name="surname"
            placeholder={lastName}
            required={true}
            ref={form.register({
              required: 'Required'
            })}
          />
        </div>
        <div className="col-12 col-lg-6 mb-32">
          <FormInput
            type="email"
            name="email"
            placeholder={email}
            required={true}
            ref={form.register({
              required: 'Required'
            })}
          />
        </div>
        <div className="col-12 col-lg-6 mb-32">
          <FormInput
            type="tel"
            name="tel"
            placeholder={phoneNumber}
            required={true}
            ref={form.register({
              required: 'Required'
            })}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 ta-center">
          <button type="submit" className="btn btn--dark">
            {step1ButtonText}
          </button>
        </div>
      </div>
    </form>
  )
}

export default Form
