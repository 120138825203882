import React from 'react'

import FormFile from '@c/form-file'
import FormTextarea from '@c/form-textarea'

const Form = ({
  locale,

  step3FileSelectDescription,
  step3UploadYourCv,
  step3SubmitButtonText,
  step3TextTitle,

  onNextButtonClick,

  form
}) => {
  return (
    <form onSubmit={onNextButtonClick.bind(this)}>
      <p>{step3FileSelectDescription}</p>
      <div className="row mt-24">
        <div className="col-12 mb-32">
          <FormFile
            required={true}
            accept=".pdf,.doc,.docx"
            name="cv"
            locale={locale}
            ref={form.register()}
          >
            {step3UploadYourCv}
          </FormFile>
        </div>
      </div>

      <p className="mt-40">{step3TextTitle}</p>
      <div className="row mt-24">
        <div className="col-12 mb-32">
          <FormTextarea
            name="on-yazi"
            placeholder={step3TextTitle}
            required={true}
            ref={form.register()}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 ta-center">
          <button type="submit" className="btn btn--dark">
            {step3SubmitButtonText}
          </button>
        </div>
      </div>
    </form>
  )
}

export default Form
