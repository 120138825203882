import React, { useCallback, useState } from 'react'
import cn from 'classnames'
import { graphql } from 'gatsby'
import { useForm } from 'react-hook-form'

import Layout from '@c/layout'
import ProgressBar from '@c/progress-bar'
import FlexibleLink from '@c/common/FlexibleLink'
import Modal from '@c/modal'

import Form1 from '@v/hello/join-1-form'
import Form1Service from '@v/hello/service-1-form'
import Form2Service from '@v/hello/service-2-form'
import Form2 from '@v/hello/join-2-form'
import Form3 from '@v/hello/join-3-form'
import HelloContactPage from '../../models/HelloContactPage'

const GATSBY_HELLO_APPLY_FORM_POST_URL =
  process.env.GATSBY_HELLO_APPLY_FORM_POST_URL
const GATSBY_HELLO_SERVICE_FORM_POST_URL =
  process.env.GATSBY_HELLO_SERVICE_FORM_POST_URL
const GATSBY_CONTACT_FORM_POST_URL = process.env.GATSBY_CONTACT_FORM_POST_URL;

// [START Steps]

const Step1Apply = props => {
  const { step1TitleSmall, step1TitleBig, visible } = props

  return (
    <div style={visible ? {} : { display: 'none', visibility: 'hidden' }}>
      <div className="row">
        {/* Header */}
        <div className="col-12 mb-60">
          <h4 className="fw-head-normal">{step1TitleSmall}</h4>
          <h1 className="h2">{step1TitleBig}</h1>
          <div className="mt-24">
            <ProgressBar percent={50} />
          </div>
        </div>
      </div>

      <Form1 {...props} />
    </div>
  )
}

const Step1Service = props => {
  const { step1TitleSmall, step1TitleBig, visible } = props

  return (
    <div style={visible ? {} : { display: 'none', visibility: 'hidden' }}>
      <div className="row">
        {/* Header */}
        <div className="col-12 mb-60">
          <h4 className="fw-head-normal">{step1TitleSmall}</h4>
          <h1 className="h2">{step1TitleBig}</h1>
          <div className="mt-24">
            <ProgressBar percent={50} />
          </div>
        </div>
      </div>

      <Form1Service {...props} />
    </div>
  )
}

const Step2Service = props => {
  const { step2TitleSmall, step2TitleBig, visible } = props

  return (
    <div style={visible ? {} : { display: 'none', visibility: 'hidden' }}>
      <div className="row">
        {/* Header */}
        <div className="col-12 mb-60">
          <h4 className="fw-head-normal">{step2TitleSmall}</h4>
          <h1 className="h2">{step2TitleBig}</h1>
          <div className="mt-24">
            <ProgressBar percent={100} />
          </div>
        </div>
      </div>

      <Form2Service {...props} />
    </div>
  )
}

const Step2 = props => {
  return (
    <div style={props.visible ? {} : { display: 'none', visibility: 'hidden' }}>
      <div className="row">
        {/* Header */}
        <div className="col-12 mb-60">
          <h4 className="fw-head-normal">Step 2</h4>
          <h1 className="h2">Join the Zeo Team</h1>
          <div className="mt-24">
            <ProgressBar percent={75} />
          </div>
        </div>
      </div>

      <Form2 {...props} />
    </div>
  )
}

const Step3 = props => {
  return (
    <div style={props.visible ? {} : { display: 'none', visibility: 'hidden' }}>
      <div className="row">
        {/* Header */}
        <div className="col-12 mb-60">
          <h4 className="fw-head-normal">Step 3</h4>
          <h1 className="h2">Lastly..</h1>
          <div className="mt-24">
            <ProgressBar percent={100} />
          </div>
        </div>
      </div>

      <Form3 {...props} />
    </div>
  )
}

const Step4 = ({
  step4Title,
  step4Description,
  step4ButtonText,
  step4ButtonUrl,
  visible
}) => {
  return (
    <div style={visible ? {} : { dislay: 'none', visibility: 'hidden' }}>
      <h1 className="h2">{step4Title}</h1>
      <p className="mt-24">{step4Description}</p>
      <div className="mt-32">
        <FlexibleLink
          url={step4ButtonUrl}
          title={step4ButtonText}
          className="btn btn--dark"
        />
      </div>
    </div>
  )
}

// [END Steps]

const Page = ({ data }) => {
  const { helloPage, helloPages, page } = data
  const { isServicePage, locale } = page
  const { submitSuccessMessage, submitFailMessage } = helloPage
  const slugLocales = helloPage._allContactCardsAndPagesLocales.map(
    contactLocale => ({
      locale: contactLocale.locale,
      value: contactLocale.value.find(
        val => val.isServicePage === isServicePage
      ).slug
    })
  )
  const model = new HelloContactPage(page, { locale, slugLocales })
  const [state, setState] = useState(() => ({
    step: 1,
    modalToggle: 'd-none',
    modalContent: ''
  }))
  const form = useForm()

  const incrementStep = useCallback(
    (data, e) => {
      if (e?.preventDefault) {
        e.preventDefault()
      }
      setState({
        ...state,
        step: state.step + 1
      })
    },
    [state, setState]
  )
  const onSubmit = useCallback(
    e => {
      const values = form.getValues()
      const formData = new FormData()
      let answers1 = []
      let answers2 = []

      Object.keys(values).forEach(element => {
        // get file data
        if (values[element] instanceof FileList && values[element].length > 0) {
          values[element] = values[element][0]
        }

        if (!element.startsWith('step')) {
          formData.append(element, values[element])
        }

        // get form data
        if (element.startsWith('step2-question1')) {
          if (values[element]) {
            answers1.push(element.split('-option-')[1])
          }
        } else if (element.startsWith('step2-question2')) {
          if (values[element]) {
            answers2.push(element.split('-option-')[1])
          }
        }
      })

      formData.append('step2-question1-answers', answers1)
      if (!isServicePage) {
        formData.append('step2-question2-answers', answers2)
      }

      const url = isServicePage
        ? GATSBY_HELLO_SERVICE_FORM_POST_URL
        : GATSBY_HELLO_APPLY_FORM_POST_URL

      setState({
        modalToggle: 'modal',
        modalContent: locale === 'en' ? 'Please wait' : 'Lütfen Bekleyiniz.'
      })

      if (isServicePage) {
        fetch(GATSBY_CONTACT_FORM_POST_URL, {
          method: 'post',
          body: formData
        })
          .catch(() => {
            console.log('contact form notification couldn\'t be sent')
          })
      }

      fetch(url, {
        method: 'post',
        body: formData
      })
        .then(async response => ({ response, body: await response.text() }))
        .then(() => {
          setState({ modalToggle: 'd-none' })
          form.reset()
          incrementStep({}, e)
        })
        .catch(() => {
          setState({
            modalToggle: 'modal',
            modalContent:
              locale === 'en'
                ? 'Something went wrong. Please try again.'
                : 'Bir hata oluştu, lüften tekrar deneyiniz.'
          })
          setTimeout(() => {
            setState({ modalToggle: 'd-none' })
          }, 2000)
        })
    },
    [
      form,
      isServicePage,
      incrementStep,
      submitSuccessMessage,
      submitFailMessage
    ]
  )

  // console.log('hello contact', { model, slugLocales, helloPage, helloPages })

  return (
    <Layout
      locale={page.locale}
      seo={{ ...model.getSeoComponentProps(helloPages.nodes) }}
    >
      <div className="container">
        <div className="row">
          <div
            className={cn(
              'col-12',
              'col-lg-8',
              'offset-lg-2',
              // last number is 3 for service page, 4 for job application page
              ((page.isServicePage && state.step === 3) ||
                (!page.isServicePage && state.step === 4)) &&
                'ta-center'
            )}
          >
            {isServicePage && (
              <Step1Service
                visible={state.step === 1}
                {...page}
                onNextButtonClick={form.handleSubmit(incrementStep)}
                form={form}
              />
            )}
            {isServicePage && state.step === 2 && (
              <Step2Service
                visible={state.step === 2}
                {...page}
                onNextButtonClick={form.handleSubmit(onSubmit)}
                form={form}
              />
            )}
            {!isServicePage && (
              <Step1Apply
                visible={state.step === 1}
                {...page}
                onNextButtonClick={form.handleSubmit(incrementStep)}
                form={form}
              />
            )}
            {!isServicePage && (
              <Step2
                visible={!isServicePage && state.step === 2}
                {...page}
                onNextButtonClick={form.handleSubmit(incrementStep)}
                form={form}
              />
            )}
            {!isServicePage && (
              <Step3
                visible={!isServicePage && state.step === 3}
                {...page}
                onNextButtonClick={form.handleSubmit(onSubmit)}
                form={form}
              />
            )}
            <Step4
              visible={page.isServicePage ? state.step === 3 : state.step === 4}
              {...page}
            />
          </div>
        </div>
        <Modal
          modalToggle={state.modalToggle}
          modalContent={state.modalContent}
        />
      </div>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query HelloServiceOrContactPageQuery($id: String, $locale: String) {
    helloPage: datoCmsHelloPage(locale: { eq: $locale }) {
      _allContactCardsAndPagesLocales {
        locale
        value {
          ... on DatoCmsHelloContactPage {
            isServicePage
            slug
          }
        }
      }

      submitSuccessMessage
      submitFailMessage
    }

    helloPages: allDatoCmsHelloPage {
      nodes {
        locale
        value: slug
      }
    }

    page: datoCmsHelloContactPage(id: { eq: $id }) {
      locale
      title
      isServicePage

      step1TitleSmall
      step1TitleBig
      step2TitleSmall
      step2TitleBig
      firstName
      lastName
      email
      phoneNumber
      companyName
      message
      step1ButtonText

      step2TitleSmall
      step2TitleBig
      step2Question1
      step2Question1Options
      step2Question2
      step2Question2Options
      step2ButtonText

      step3TitleSmall
      step3TitleBig
      step3FileSelectDescription
      step3UploadYourCv
      step3NoFileSelected
      step3TextTitle
      step3SubmitButtonText

      step4Title
      step4Description
      step4ButtonText
      step4ButtonUrl
    }
  }
`
