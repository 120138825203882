import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import cn from 'classnames'

import getContentByLocale from '@u/get-content-by-locale'

const query = graphql`
  query {
    translations: allDatoCmsTranslation {
      edges {
        node {
          locale
          noFileSelected
        }
      }
    }
  }
`

function FormFileWithRef({ children, onChange, ...props }, ref) {
  const [file, setFile] = useState(null)
  const {
    translations: { edges: translationEdges }
  } = useStaticQuery(query)
  const translation = getContentByLocale(
    props.locale,
    translationEdges,
    'node.locale'
  )?.node

  const onHandleChange = event => {
    const file = event.target.files[0]
    if (!file) return
    setFile(file)
    onChange(file)
  }

  return (
    <div className={cn('form-file', { 'form--required': props.required })}>
      <label>
        <input type="file" {...props} onChange={onHandleChange} ref={ref} />
        {file ? (
          <span>{file.name}</span>
        ) : (
          <span>
            {children}{' '}
            <span className="c-dark-40">({translation?.noFileSelected})</span>
          </span>
        )}
      </label>
    </div>
  )
}

const FormFile = React.forwardRef(FormFileWithRef)

FormFile.defaultProps = {
  required: false,
  onChange: () => {}
}

FormFile.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node
}

export default FormFile
