import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

function FormTextareaWithRef(props, ref) {
  return (
    <div className={cn('form-textarea', { 'form--required': props.required })}>
      <textarea {...props} ref={ref} />
    </div>
  )
}

const FormTextarea = React.forwardRef(FormTextareaWithRef)

FormTextarea.defaultProps = {
  required: false,
  rows: 3,
  onChange: () => {}
}

FormTextarea.propTypes = {
  rows: PropTypes.number,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func
}

export default FormTextarea
